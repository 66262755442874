document.addEventListener('DOMContentLoaded', function () {
  function randomInteger(min, max) {
    let rand = min + Math.random() * (max - min)
    return Math.floor(rand)
  }

  // активируем главный слайдер
  if (document.querySelector('#promoSlider')) {
    const slidesCount = document.querySelectorAll('.promo__slide').length
    const randomSlide = randomInteger(0, slidesCount)

    const promoSlider = new Glide('#promoSlider', {
      type: 'carousel',
      perView: 3,
      autoplay: 8000,
      gap: 15,
      hoverpause: true,
      animationDuration: 500,
      animationTimingFunc: 'ease-in-out',
      startAt: randomSlide,
      breakpoints: {
        480: {
          perView: 1
        },
        768: {
          perView: 2
        }
      }
    })
    promoSlider.mount()
  }

  // функция транслита
  function toTranslit(text) {
    return text.replace(/([а-яё])|([\s_-])|([^a-z\d])/gi, function (all, ch, space, words, i) {
      if (space || words) {
        return space ? '-' : ''
      }
      var code = ch.charCodeAt(0),
        index = code == 1025 || code == 1105 ? 0 : code > 1071 ? code - 1071 : code - 1039,
        t = [
          'yo',
          'a',
          'b',
          'v',
          'g',
          'd',
          'e',
          'zh',
          'z',
          'i',
          'y',
          'k',
          'l',
          'm',
          'n',
          'o',
          'p',
          'r',
          's',
          't',
          'u',
          'f',
          'h',
          'c',
          'ch',
          'sh',
          'shch',
          '',
          'y',
          '',
          'e',
          'yu',
          'ya'
        ]
      return t[index]
    })
  }

  // получение случайного числа
  function getRandomArbitrary(min, max) {
    return Math.floor(Math.random() * (max - min) + min)
  }

  // активируем главный слайдер
  if (document.querySelector('#mainSlider')) {
    const slidesCount = document.querySelectorAll('.slider-main__slide').length
    const randomSlide = randomInteger(0, slidesCount)

    const mainSlider = new Glide('#mainSlider', {
      type: 'carousel',
      perView: 1,
      autoplay: 15000,
      hoverpause: true,
      animationDuration: 500,
      animationTimingFunc: 'ease-in-out',
      startAt: randomSlide
    })
    mainSlider.mount()
  }

  // активируем слайдер в сайдбаре
  if (document.querySelector('#sidebarSlider')) {
    const slidesCount = document.querySelectorAll('.slider-sidebar__slide').length
    const randomSlide = randomInteger(0, slidesCount)

    const sidebarSlider = new Glide('#sidebarSlider', {
      type: 'carousel',
      perView: 1,
      autoplay: 10000,
      hoverpause: true,
      animationDuration: 500,
      animationTimingFunc: 'ease-in-out',
      startAt: randomSlide,
      breakpoints: {
        1023: {
          perView: 2
        },
        576: {
          perView: 1
        }
      }
    })
    sidebarSlider.mount()
  }

  // меню поиска при скролле
  if (document.querySelector('.mobile-search')) {
    const search = document.querySelector('.search')
    const searchMobile = document.querySelector('.mobile-search')

    const searchMobileInput = searchMobile.querySelector('input')
    const searchInput = search.querySelector('input')

    const results = search.querySelector('.search__results')
    const resultsMobile = searchMobile.querySelector('.mobile-search__results')

    window.addEventListener('scroll', function () {
      if (window.pageYOffset > 200) {
        searchMobile.style.display = 'block'
        searchInput.value = ''
        results.innerHTML = ''
      } else {
        searchMobile.style.display = 'none'
        searchMobileInput.value = ''
        resultsMobile.innerHTML = ''
      }
    })
  }

  // открываем текст при клике
  if (document.querySelectorAll('.vip__more-button')) {
    const adsButtons = document.querySelectorAll('.vip__more-button')

    adsButtons.forEach(function (element) {
      element.addEventListener('click', function () {
        const dots = this.previousElementSibling.querySelector('.vip__collapse-dots')
        const textMore = this.previousElementSibling.querySelector('.vip__collapse-more')
        const btn = this

        if (dots.style.display === 'none') {
          dots.style.display = 'inline'
          btn.innerHTML = 'Читать полностью...'
          textMore.style.display = 'none'
        } else {
          dots.style.display = 'none'
          btn.innerHTML = 'Свернуть'
          textMore.style.display = 'inline'
        }
      })
    })
  }

  // работа дровера
  if (
    document.getElementById('headerBtn') &&
    document.querySelector('.blackscreen') &&
    document.querySelector('.header__drawer')
  ) {
    const headerBtn = document.getElementById('headerBtn')
    const blackscreen = document.querySelector('.blackscreen')
    const drawer = document.querySelector('.header__drawer')

    // Показываем дровер
    function showDrawer() {
      drawer.style.display = 'block'

      setTimeout(() => {
        blackscreen.classList.add('blackscreen--active')
        headerBtn.classList.add('header__menu--active')
        drawer.classList.add('header__drawer--active')
      }, 100)
    }

    // Убираем дровер
    function hideDrawer() {
      blackscreen.classList.remove('blackscreen--active')
      headerBtn.classList.remove('header__menu--active')
      drawer.classList.remove('header__drawer--active')

      setTimeout(() => {
        drawer.style.display = 'none'
      }, 500)
    }

    // Обработка клика на гамбургер
    headerBtn.addEventListener('click', function () {
      if (this.classList.contains('header__menu--active')) {
        hideDrawer()
      } else {
        showDrawer()
      }
    })

    blackscreen.addEventListener('click', function () {
      if (this.classList.contains('blackscreen--active')) {
        hideDrawer()
      } else {
        showDrawer()
      }
    })
  }

  // управляем модалкой
  if (document.querySelectorAll('.vip__lead')) {
    const leadButtons = document.querySelectorAll('.vip__lead')
    const blackscreen = document.querySelector('.blackscreen')
    const formClose = document.querySelectorAll('.vip__form-close')

    leadButtons.forEach(function (element) {
      element.addEventListener('click', function (event) {
        const form = this.parentElement.parentElement.querySelector('.vip__form')
        const captchaField = form.querySelector('input[name="captcha_1"]')
        captchaField.classList.add('vip__form-field')
        captchaField.classList.add('vip__form-field--empty')
        const formFields = form.querySelectorAll('.vip__form-field')
        const formBtn = form.querySelector('.vip__form-btn')

        event.preventDefault()

        blackscreen.classList.add('blackscreen--active')

        if (!form.classList.contains('vip__form--active')) {
          form.classList.add('vip__form--active')
        }

        // проверка формы
        setInterval(function () {
          // валидация полей
          formFields.forEach(function (element) {
            if (element.value === '' || element.value.length > 50) {
              element.classList.add('vip__form-field--empty')
            } else {
              element.classList.remove('vip__form-field--empty')
            }
          })

          const sizeEmpty = form.querySelectorAll('.vip__form-field--empty').length

          if (sizeEmpty > 0) {
            if (formBtn.classList.contains('vip__form-btn--disabled')) {
              return
            } else {
              formBtn.classList.add('vip__form-btn--disabled')
            }
          } else {
            formBtn.classList.remove('vip__form-btn--disabled')
          }
        }, 500)
      })
    })

    blackscreen.addEventListener('click', function () {
      const activeForm = document.querySelector('.vip__form--active')

      if (activeForm) {
        activeForm.classList.remove('vip__form--active')
      }

      if (blackscreen) {
        blackscreen.classList.remove('blackscreen--active')
      }
    })

    formClose.forEach(function (element) {
      element.addEventListener('click', function () {
        const activeForm = document.querySelector('.vip__form--active')

        if (activeForm) {
          activeForm.classList.remove('vip__form--active')
        }

        if (blackscreen) {
          blackscreen.classList.remove('blackscreen--active')
        }
      })
    })
  }

  // калькулятор
  if (document.querySelector('.calculator')) {
    const calculator = document.querySelector('.calculator')

    calculator.addEventListener('input', function (event) {
      // отображаем значение ползунка
      const element = event.target
      const number = element.previousElementSibling.querySelector('span')
      number.innerText = element.value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')

      // считаем платеж
      const sum = document.getElementById('rangeSum').value
      const time = document.getElementById('rangeTime').value
      const percent = document.getElementById('rangePercent').value
      const resultMonthPay = document.getElementById('resultMonthPay')
      const resultOverpayment = document.getElementById('resultOverpayment')
      const resultTotalPayment = document.getElementById('resultTotalPayment')

      const i = percent / 12 / 100
      const koef = (i * Math.pow(1 + i, time * 12)) / (Math.pow(1 + i, time * 12) - 1)

      let payPerMonth = sum * koef
      payPerMonth = payPerMonth.toFixed()

      // Считаем переплату
      let overpayment = payPerMonth * (time * 12) - sum

      // Считаем переплату
      let totalPayment = parseInt(sum) + parseInt(overpayment)

      resultMonthPay.querySelector('span').innerText = payPerMonth.replace(
        /(\d)(?=(\d\d\d)+([^\d]|$))/g,
        '$1 '
      )
      resultOverpayment.querySelector('span').innerText = overpayment
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
      resultTotalPayment.querySelector('span').innerText = totalPayment
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
    })
  }

  // активируем аккордионы
  if (document.querySelector('.accordion__tag') && document.querySelector('.accordion__city')) {
    new Accordion(['.accordion__tag', '.accordion__city'], {
      collapse: true,
      showMultiple: true
    })
  }

  // проверяем форму с комментарием
  if (document.querySelector('.comments__form')) {
    const commentsForm = document.querySelector('.comments__form')
    const commentsFormInputs = commentsForm.querySelectorAll('.comments__field')
    const commentsFormTextarea = commentsForm.querySelector('.comments__textarea')
    const commentsFormCaptcha = commentsForm.querySelector('.comments__captcha input[type="text"]')

    const commentsFormBtn = commentsForm.querySelector('.comments__btn')

    setInterval(function () {
      // валидация полей
      commentsFormInputs.forEach(function (element) {
        if (element.value === '' || element.value.length <= 2 || element.value.length > 30) {
          element.classList.add('comments__field--empty')
        } else {
          element.classList.remove('comments__field--empty')
        }

        if (
          commentsFormTextarea.value === '' ||
          commentsFormTextarea.value.length <= 5 ||
          commentsFormTextarea.value.length > 1000
        ) {
          commentsFormTextarea.classList.add('comments__field--empty')
        } else {
          commentsFormTextarea.classList.remove('comments__field--empty')
        }

        if (commentsFormCaptcha.value === '' || commentsFormCaptcha.value.length <= 0) {
          commentsFormCaptcha.classList.add('comments__field--empty')
        } else {
          commentsFormCaptcha.classList.remove('comments__field--empty')
        }

        const sizeEmpty = commentsForm.querySelectorAll('.comments__field--empty').length

        if (sizeEmpty > 0) {
          if (commentsFormBtn.classList.contains('comments__btn--disabled')) {
            return
          } else {
            commentsFormBtn.classList.add('comments__btn--disabled')
          }
        } else {
          commentsFormBtn.classList.remove('comments__btn--disabled')
        }
      })
    }, 500)
  }

  // управляем сообщениями
  if (document.querySelector('.messages')) {
    const message = document.querySelector('.messages')
    const messageBtn = document.querySelector('.messages__cross')

    message.classList.add('messages--active')

    messageBtn.addEventListener('click', function () {
      message.classList.remove('messages--active')
    })

    setTimeout(() => {
      message.classList.remove('messages--active')
    }, 10000)
  }

  // список городов
  if (document.getElementById('formRegions')) {
    const formRegions = new Choices('#formRegions', {
      removeItemButton: true,
      noResultsText: 'Выберите города из списка',
      noChoicesText: 'Все города уже выбраны',
      itemSelectText: 'Добавить город',
      placeholder: true,
      placeholderValue: 'Выберите города'
    })
  }

  // список ключевиков
  if (document.getElementById('formTags')) {
    const formTags = new Choices('#formTags', {
      removeItemButton: true,
      noResultsText: 'Выберите ключевые слова из списка',
      noChoicesText: 'Все ключевые слова уже выбраны',
      itemSelectText: 'Добавить ключ',
      placeholder: true,
      placeholderValue: 'Выберите ключ'
    })
  }

  // валидация формы объявления
  if (document.querySelector('.addform')) {
    const form = document.querySelector('.addform')
    const title = form.querySelector('#createFormTitle')
    const slug = form.querySelector('#createFormSlug')
    const textarea = form.querySelector('textarea.addform__input')
    const counter = form.querySelector('.addform__description-addinfo span')

    const addFormInputs = form.querySelectorAll('.addform__input')

    textarea.addEventListener('keyup', function () {
      counter.textContent = 1000 - this.value.length
    })

    const addFormBtn = form.querySelector('.addform__btn')

    title.addEventListener('change', function () {
      slug.value = `${getRandomArbitrary(1, 999999)}-${toTranslit(this.value)}`
    })

    const addFormCaptcha = form.querySelector('.addform__bottom-captcha input[type="text"]')

    setInterval(function () {
      // валидация полей
      addFormInputs.forEach(function (element) {
        if (element.value === '' || element.value.length <= 1 || element.value.length > 1000) {
          element.classList.add('addform__input--empty')
        } else {
          element.classList.remove('addform__input--empty')
        }

        if (addFormCaptcha.value === '' || addFormCaptcha.value.length <= 0) {
          addFormCaptcha.classList.add('addform__input--empty')
        } else {
          addFormCaptcha.classList.remove('addform__input--empty')
        }

        const sizeEmpty = form.querySelectorAll('.addform__input--empty').length

        if (sizeEmpty > 0) {
          if (addFormBtn.classList.contains('addform__btn--disabled')) {
            return
          } else {
            addFormBtn.classList.add('addform__btn--disabled')
          }
        } else {
          addFormBtn.classList.remove('addform__btn--disabled')
        }
      })
    }, 500)
  }

  // подсветка поиска
  if (document.querySelector('.title--search')) {
    const vipHeader = document.querySelectorAll('.vip__header a')
    const normalHeader = document.querySelectorAll('.normal__header a')
    const titleWord = document.querySelector('.title--search span').innerText

    let newWord = `<span class="search__colored">${titleWord}</span>`
    let regEx = new RegExp(titleWord, 'gi')

    vipHeader.forEach(function (element) {
      let headerText = element.innerText
      let output = headerText.replace(regEx, newWord)
      element.innerHTML = output
    })

    normalHeader.forEach(function (element) {
      let headerText = element.innerText
      let output = headerText.replace(regEx, newWord)
      element.innerHTML = output
    })
  }

  // прокрутка наверх
  if (document.querySelector('.arrow')) {
    const adsArrow = document.querySelector('.arrow')

    if (window.pageYOffset >= 1000) {
      if (adsArrow.style.opacity != 1) {
        adsArrow.style.opacity = 1
        adsArrow.style.zIndex = 1
      }
    } else {
      adsArrow.style.opacity = 0
      adsArrow.style.zIndex = -5
    }

    // Добавление кнопки прокрутки наверх
    window.addEventListener('scroll', function () {
      if (window.pageYOffset >= 1000) {
        if (adsArrow.style.opacity != 1) {
          adsArrow.style.opacity = 1
          adsArrow.style.zIndex = 1
        }
      } else {
        adsArrow.style.opacity = 0
        adsArrow.style.zIndex = -5
      }
    })

    // скролл по клику
    adsArrow.addEventListener('click', function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
  }

  // асинхронный поиск
  ;(function ($, undefined) {
    $('#search-input').keyup(function () {
      $.ajax({
        type: 'GET',
        url: '/live-search',
        data: {
          search_name: $('#search-input').val()
        },
        success: searchSuccess,
        dataType: 'html'
      })
    })

    function searchSuccess(data, textStatus, jqXHR) {
      $('#search-results').html(data)
    }

    $('#search-input-mobile').keyup(function () {
      $.ajax({
        type: 'GET',
        url: '/live-search',
        data: {
          search_name: $('#search-input-mobile').val()
        },
        success: searchSuccessMobile,
        dataType: 'html'
      })
    })

    function searchSuccessMobile(data, textStatus, jqXHR) {
      $('#search-results-mobile').html(data)
    }
  })(jQuery)
})
